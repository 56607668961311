import React from "react";
import ReactDOM from "react-dom";
import {App} from './App';
import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom";


ReactDOM.render(
    <RecoilRoot>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </RecoilRoot>,
    document.getElementById("root")
);
