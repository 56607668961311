import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import {Box, CircularProgress} from "@mui/material";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ResponsiveAppBar from "./components/AppBar";
import {useRecoilState, useRecoilValue} from "recoil";
import {isLoading, userData} from "./state/atoms";
import {isAuth} from "./state/selectors";
import {me, refresh} from "./http/userAPI";
import DateAdapter from '@mui/lab/AdapterMoment';


export function App() {
    const isUserAuth = useRecoilValue(isAuth);
    const [user, setUser] = useRecoilState(userData);
    const [showSpinner, setIsLoading] = useRecoilState(isLoading);


    useEffect(() => {
        const checkLogin = async () => {
            if (localStorage.getItem('access_token')) {
                try {
                    const _user = await me();
                    setUser(_user);
                } catch {
                    await refresh();
                    const _user = await me();
                    setUser(_user);
                }
            }
        }
        checkLogin()
            .then(_ => setIsLoading(false))
            .catch(_ => setIsLoading(false));

    }, [])

    if (showSpinner) {
        return <Box component='div'
                    sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
            <CircularProgress/>
        </Box>;
    }

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
                {isUserAuth && <ResponsiveAppBar/>}
                <AppRouter/>
        </LocalizationProvider>
    );

}