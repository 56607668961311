import React, {useState} from 'react';
import ExampleCard from "./ExampleCard";
import {Card, CardContent, Icon, Typography} from "@mui/material";
import ExampleEditor from './ExampleEditor';
import {useRecoilValue} from "recoil";
import {articleExamples} from "../state/atoms";


const ExampleCardList = () => {
    const [isEditorShow, setIsEditorShow] = useState(false)
    const examples = useRecoilValue(articleExamples)

    const prompt = <CardContent sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Icon>add_icon</Icon>
    </CardContent>

    return (<>
            <Typography>Примеры</Typography>
            <Card elevation={3} sx={{cursor: "pointer", padding: "5px"}} onClick={() => setIsEditorShow(true)}>
                {isEditorShow ? <ExampleEditor close={setIsEditorShow}/> : prompt}
            </Card>
            {examples.map((example, idx) =>
                <ExampleCard key={idx} {...example} />
            )}
        </>
    )
};

export default ExampleCardList;