import {atom} from "recoil";
import User from "../models/User";
import {Article, Example, LanguageCode} from "../models/Article";

export const userData = atom({
    key: 'userData',
    default: new User(),
})

export const isLoading = atom({
    key: 'isLoading',
    default: true,
})

export const articlesList = atom<Article[]>({
    key: 'allArticlesList',
    default: [],
})

export const chosenLanguage = atom({
    key: 'chosenLanguage',
    default: LanguageCode.ru,
})

export const articleHeader = atom({
    key: 'articleHeader',
    default: ''
})

export const articleTermin = atom({
    key: 'articleTermin',
    default: ''
})
export const articleDefinition = atom({
    key: 'articleDefinition',
    default: ''
})
export const articleExamples = atom<Example[]>({
    key: 'articleExamples',
    default: []
})