import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {isAuth} from "../../state/selectors";


// @ts-ignore
export const GuestRoute = ({children, ...rest}) => {
    const isUserAuth = useRecoilValue(isAuth);
    const location = useLocation();
    const url = new URLSearchParams(location.search.slice(1));

    return isUserAuth ? <Navigate to={url.get("redirect") || "/"}/> : children;
}