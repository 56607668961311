import axios from "axios";

const $host = axios.create({
    baseURL: process.env.REACT_APP_UNI_URL
});

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_UNI_URL
});

// @ts-ignore
const authInterceptor = (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
};

$authHost.interceptors.request.use(authInterceptor);

export {$host, $authHost};