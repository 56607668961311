import React, {useState} from 'react';
import {Button, Stack, TextField} from "@mui/material";
import {useRecoilState, useRecoilValue} from "recoil";
import {articleExamples, chosenLanguage} from "../state/atoms";
import {Example, LanguageCode} from "../models/Article";
import {useParams} from "react-router-dom";
import {getArticleById, updateArticle} from "../http/articlesAPI";

interface IExampleEditorProps {
    close: (val: boolean) => void
}

const ExampleEditor = ({close}: IExampleEditorProps) => {
    const [text, setText] = useState('')
    const [source, setSource] = useState('')
    const [uri, setUri] = useState('')

    const [examples, setExamples] = useRecoilState(articleExamples)
    const language = useRecoilValue(chosenLanguage)
    const {id} = useParams()

    const closeExampleEditor = (e: any) => {
        e.stopPropagation()
        close(false)
    }

    const saveArticleWithNewExample = (e: any) => {
        e.stopPropagation()
        const entry = {text, source, uri} as Example
        const newList = [...examples, entry]
        setExamples(newList)
        if (id) {
            getArticleById(id)
                .then((article) => {
                    switch (language) {
                        case LanguageCode.ba:
                            article.ba.examples = newList
                            break;
                        case LanguageCode.en:
                            article.en.examples = newList
                            break;
                        case LanguageCode.ru:
                            article.ru.examples = newList
                            break;
                    }
                    updateArticle(article).then(r => {
                        close(false)
                    })

                })
        }
    }

    return (<Stack spacing={2}>
            <TextField label="Текст примера" value={text} onChange={e => {
                setText(e.target.value)
            }}/>
            <TextField label="Источник" value={source} onChange={e => {
                setSource(e.target.value)
            }}/>
            <TextField label="Ссылка на источник" value={uri} onChange={e => {
                setUri(e.target.value)
            }}/>
            <Stack direction="row"
                   justifyContent="flex-start"
                   alignItems="center"
                   spacing={2}>
                <Button variant="contained" color="secondary" onClick={(e) => {
                    closeExampleEditor(e)
                }}>отменить</Button>
                <Button variant="contained" color="success" onClick={(e) => {
                    saveArticleWithNewExample(e)
                }}>сохранить</Button>
            </Stack>
        </Stack>
    )
}

export default ExampleEditor