import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {LoginPage} from "../pages/LoginPage";
import NewsEditorPage from "../pages/NewsEditorPage";
import MePage from "../pages/MePage";
import {useRecoilValue} from "recoil";
import {isAuth} from "../state/selectors";
import {PrivateRoute} from "./router/PrivateRoute";
import {GuestRoute} from "./router/GuestRoute";
import ArticlesPage from "../pages/ArticlesPage";

const AppRouter = () => {
    const isUserAuth = useRecoilValue(isAuth);
    return (
        <Routes>
            <Route
                path="/me"
                element={
                    <PrivateRoute>
                        <MePage/>
                    </PrivateRoute>
                }
            />
            {/*<Route*/}
            {/*    path="/news"*/}
            {/*    element={*/}
            {/*        <PrivateRoute>*/}
            {/*            <NewsEditorPage/>*/}
            {/*        </PrivateRoute>*/}
            {/*    }*/}
            {/*/>*/}
            <Route
                path="/articles/:id"
                element={
                    <PrivateRoute>
                        <ArticlesPage/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/articles"
                element={
                    <PrivateRoute>
                        <ArticlesPage/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/login"
                element={
                    <GuestRoute>
                        <LoginPage/>
                    </GuestRoute>
                }
            />

            <Route path="*" element={!isUserAuth && <Navigate to="/login"/>}/>
        </Routes>
    );
};

export default AppRouter;