import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {isAuth} from "../../state/selectors";
import {LOGIN_ROUTE} from "../../utils/consts";


// @ts-ignore
export const PrivateRoute = ({children}) => {
    const isUserAuth = useRecoilValue(isAuth);
    const location = useLocation();
    const url = new URLSearchParams();
    url.set("redirect", location.pathname + location.search);

    return isUserAuth ? (
        children
    ) : (
        <Navigate
            to={{
                pathname: LOGIN_ROUTE,
                search: url.toString(),
            }}
        />
    );
}