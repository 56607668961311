import React from 'react'
import {Button, Card, CardActions, CardContent, Link, Typography} from "@mui/material"
import {Example} from "../models/Article"

const ExampleCard = (example: Example) => {

    if (example.text.length === 0) {
        return <></>
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="body2">
                    {example.text}
                </Typography>
                <Link href={example.uri}>{example.source}</Link>
                {/*<CardActions>*/}
                {/*    <Button size="small">Изменить</Button>*/}
                {/*</CardActions>*/}
            </CardContent>
        </Card>
    )

}

export default ExampleCard