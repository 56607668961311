export const LOGIN_ROUTE = '/users/login';
export const ME_ROUTE = '/users/me';
export const REFRESH_ROUTE = '/users/refresh_token';

export const NEWS_ROUTE = '/news';
export const NEWS_TITLES_ROUTE = '/news/titles';

export const ARTICLES_ROUTE = '/articles/';
export const SEARCH_ARTICLES_ROUTE = '/articles/search/';

