export class Example {
    text: string = ''
    source: string = ''
    uri: string = ''
}

export class DictEntry {
    header: string = ''
    termin: string = ''
    definition: string = ''
    examples: Example[] = []
}

export class Article {
    id: string = ''
    ba: DictEntry = new DictEntry()
    en: DictEntry = new DictEntry()
    ru: DictEntry = new DictEntry()
    search_count: Number = 0
}

export class ArticleIn {
    ba: DictEntry = new DictEntry()
    en: DictEntry = new DictEntry()
    ru: DictEntry = new DictEntry()
}

export enum LanguageCode {
    ru = 'ru',
    en = 'en',
    ba = 'ba'
}