import {Article, ArticleIn} from "../models/Article";
import {$authHost, $host} from "./index";
import {ARTICLES_ROUTE, SEARCH_ARTICLES_ROUTE} from "../utils/consts";

export const getAllArticles = async () => {
    const {data} = await $host.get<[Article]>(ARTICLES_ROUTE);
    return data;
}

export const createArticle = async (articleIn: ArticleIn) => {
    const {data} = await $authHost.post<Article>(ARTICLES_ROUTE, articleIn, {withCredentials: true});
    return data;
}

export const getArticleById = async (id: string): Promise<Article> => {
    const {data} = await $host.get<Article>(`${ARTICLES_ROUTE}${id}`);
    return data;
}

export const searchArticle = async (to_search: string) => {
    const {data} = await $host.get<[Article]>(`${SEARCH_ARTICLES_ROUTE}${to_search}`);
    return data;
}

export const updateArticle = async (article: Article) => {
    const {data} = await $authHost.put(ARTICLES_ROUTE, article, {withCredentials: true})
    return data
}