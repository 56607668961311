import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormGroup,
    Icon,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {createArticle, getAllArticles, searchArticle} from "../http/articlesAPI";
import {useRecoilState, useSetRecoilState} from "recoil";
import {articlesList, isLoading} from "../state/atoms";
import ArticleEditor from "../components/ArticleEditor";
import {useNavigate} from "react-router-dom";
import {ArticleIn, LanguageCode} from "../models/Article";

const ArticlesPage = () => {
    const [articles, setArticles] = useRecoilState(articlesList);
    const setIsLoading = useSetRecoilState(isLoading);
    const [toSearch, setToSearch] = useState('')
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [newArticleTermin, setNewArticleTermin] = useState('')
    const [newArticleLanguage, setNewArticleLanguage] = useState(LanguageCode.ru)

    const choose = (id: string) => {
        navigate(`/articles/${id}`)
        setToSearch('')
    }


    const handleClose = () => {
        setOpen(false)
        if (newArticleTermin !== '') {
            let newArticle = new ArticleIn()
            switch (newArticleLanguage) {
                case LanguageCode.ba:
                    newArticle.ba.header = newArticleTermin
                    break;
                case LanguageCode.en:
                    newArticle.en.header = newArticleTermin
                    break;
                case LanguageCode.ru:
                    newArticle.ru.header = newArticleTermin
                    break;
            }
            createArticle(newArticle).then((r) => {
            })
        }
    }

    const handleChange = (event: any) => {
        setNewArticleLanguage(event.target.value);
    };

    useEffect(() => {
        let load: () => Promise<void>
        if (toSearch === '') {
            load = async () => {
                const _articles = await getAllArticles()
                setArticles(_articles)
            }
        } else {
            load = async () => {
                const _articles = await searchArticle(toSearch)
                setArticles(_articles)
            }
        }

        load()
            .then(_ => setIsLoading(false))
            .catch(_ => setIsLoading(false));
        return

    }, [toSearch])

    useEffect(() => {
            const load = async () => {
                const _articles = await getAllArticles();
                setArticles(_articles);
            }
            load()
                .then(_ => setIsLoading(false))
                .catch(_ => setIsLoading(false));
        },
        [open]);


    return (
        <Container sx={{display: "flex", flexDirection: "row", padding: "10px"}}>
            <Stack sx={{minWidth: "30%"}}>
                <TextField label="Поиск" value={toSearch} onChange={(e) => setToSearch(e.target.value)}/>
                <List>
                    <ListItemButton sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                    onClick={() => setOpen(true)}>
                        <Icon>add_icon</Icon>
                    </ListItemButton>
                    {
                        articles.map(item =>
                            <ListItemButton key={item.id} onClick={() => choose(item.id!)}>
                                <ListItemText
                                    primary={item?.en?.header}
                                    secondary={item?.ru?.header}
                                />
                            </ListItemButton>
                        )
                    }
                </List>
            </Stack>

            <ArticleEditor elevation={4}/>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Новая словарная статья</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText>
                    <FormGroup row>
                        <Select
                            variant="outlined"
                            value={newArticleLanguage}
                            label="Язык оригинала"
                            onChange={handleChange}
                        >

                            <MenuItem value={LanguageCode.ru}>Русский</MenuItem>
                            <MenuItem value={LanguageCode.en}>Английский</MenuItem>
                            <MenuItem value={LanguageCode.ba}>Башкирский</MenuItem>
                        </Select>
                        <TextField
                            variant="outlined"
                            label="Термин в оригинале"
                            autoFocus
                            margin="dense"
                            fullWidth
                            value={newArticleTermin}
                            onChange={(e) => {
                                setNewArticleTermin(e.target.value)
                            }}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleClose}>Создать</Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
};

export default ArticlesPage;