import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";
import {LOGIN_ROUTE, ME_ROUTE, REFRESH_ROUTE} from "../utils/consts";
import User from "../models/User";

export const login = async (authData: { name: string, password: string }) => {
    const {data} = await $host.post<string>(LOGIN_ROUTE, authData, {withCredentials: true});
    localStorage.setItem('access_token', data);
    return jwt_decode(data);
}

export const me = async () => {
    const {data} = await $authHost.get<User>(ME_ROUTE, {withCredentials: true});
    return data;
}

export const refresh = async () => {
    const {data} = await $host.get<string>(REFRESH_ROUTE, {withCredentials: true});
    localStorage.setItem('access_token', data);
    return jwt_decode(data);
}