import {selector} from "recoil";
import {chosenLanguage, userData} from "./atoms";
import {Article, DictEntry, LanguageCode} from "../models/Article";

export const isAuth = selector({
    key: 'isAuth',
    get: ({get}) => {
        const user = get(userData);
        return !(user.name == '' || user.disabled);
    }
});

export const getLanguageBody = selector<(a: Article) => DictEntry>({
    key: 'getLanguageBody',
    get: ({get}) => {
        const code = get(chosenLanguage);
        return (article: Article) => {
            switch (code) {
                case LanguageCode.ba:
                    return article.ba
                case LanguageCode.en:
                    return article.en
                case LanguageCode.ru:
                default:
                    return article.ru
            }
        }
    }
})