import React, {useEffect} from 'react';
import {Box, Button, ButtonGroup, Paper, Stack, TextField} from "@mui/material";
import {DictEntry, Example, LanguageCode} from "../models/Article";
import {useRecoilState, useRecoilValue} from 'recoil';
import {articleDefinition, articleExamples, articleHeader, articleTermin, chosenLanguage} from "../state/atoms";
import {getLanguageBody} from "../state/selectors";
import {useParams} from 'react-router-dom';
import {getArticleById, updateArticle} from "../http/articlesAPI";
import ExampleCardList from "./ExampleCardList";


const languages = [
    {
        language: 'русский',
        code: LanguageCode.ru
    },
    {
        language: 'английский',
        code: LanguageCode.en
    },
    {
        language: 'башкирский',
        code: LanguageCode.ba
    },]

interface IArticleEditorProps {
    elevation: Number
}

const ArticleEditor = ({elevation}: IArticleEditorProps) => {
    const {id} = useParams()

    const [languageToShow, setLanguageToShow] = useRecoilState(chosenLanguage);
    const chooseBody = useRecoilValue(getLanguageBody);
    const [header, setHeader] = useRecoilState(articleHeader)
    const [termin, setTermin] = useRecoilState(articleTermin)
    const [definition, setDefinition] = useRecoilState(articleDefinition)
    const [examples, setExamples] = useRecoilState<Example[]>(articleExamples)

    const addExample = (example: Example) => {
        setExamples([...examples, example])
    }

    useEffect(() => {
        if (id) {
            getArticleById(id).then(article => {
                const chosenBody = chooseBody(article)
                setHeader(chosenBody.header)
                setTermin(chosenBody.termin)
                setDefinition(chosenBody.definition)
                setExamples(chosenBody.examples)
            })
        }

    }, [languageToShow, id])


    const saveArticleNow = () => {
        if (id) {
            const newDictEntry = {header, termin, definition, examples} as DictEntry
            getArticleById(id).then(article => {
                switch (languageToShow) {
                    case LanguageCode.ba:
                        article.ba = newDictEntry
                        break;
                    case LanguageCode.en:
                        article.en = newDictEntry
                        break;
                    case LanguageCode.ru:
                        article.ru = newDictEntry
                        break;
                }
                updateArticle(article).then(articleIncoming => {
                    const chosenBody = chooseBody(articleIncoming)
                    setHeader(chosenBody.header)
                    setTermin(chosenBody.termin)
                    setDefinition(chosenBody.definition)
                    setExamples(chosenBody.examples)
                })
            })

        }
    }

    return (
        // @ts-ignore
        <Stack component={Paper} elevation={elevation} spacing={2} sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "60%",
            height: "60wh",
            padding: "10px"
        }}>
            <Box component='div'>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    {languages.map(({language, code}) =>
                        <Button color={code === languageToShow ? 'success' : 'primary'} key={code}
                                onClick={() => {
                                    setLanguageToShow(code)
                                }}>{language}</Button>
                    )}
                </ButtonGroup>
            </Box>
            <Stack spacing={2}>
                <TextField label="Заголовок" value={header} onChange={e => {
                    setHeader(e.target.value)
                }}/>
                <TextField label="Термин" value={termin} onChange={e => {
                    setTermin(e.target.value)
                }}/>
                <TextField label="Определение" multiline minRows={3} value={definition} onChange={e => {
                    setDefinition(e.target.value)
                }}/>

                <ExampleCardList
                />
                <Button variant="contained" color="success" onClick={saveArticleNow}>сохранить</Button>
            </Stack>
        </Stack>
    );
};

export default ArticleEditor;