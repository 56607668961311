import React, {FormEvent, useState} from 'react';
import logo from "../images/Logo-Uni.svg";
import {Box, Button, Container, Paper, TextField} from '@mui/material';
import {login, me} from "../http/userAPI";
import {useRecoilState} from "recoil";
import {userData} from "../state/atoms";


export const LoginPage = () => {
    const [authData, setAuthData] = useState({name: '', password: ''});
    const [user, setUser] = useRecoilState(userData);
    const [error, setError] = useState(false);

    const clearInput = () => {
        setAuthData({name: '', password: ''})
    }

    const authenticate = async (e: FormEvent) => {
        try {
            setError(false)

            e.preventDefault()
            const data = await login(authData);
            const _user = await me();
            setUser(_user);
        } catch (_error) {
            setError(true)
        }
    }


    return (
        <Container sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"}}>
            <Paper elevation={3} component="form"
                   onSubmit={(e: FormEvent) => {
                       return authenticate(e);
                   }}
                   sx={{
                       display: "flex",
                       alignItems: "center",
                       justifyContent: "center",
                       flexDirection: "column",
                       padding: "15px 25px 20px",
                       gap: "10px"
                   }}>
                <Box component="img" sx={{display: "block", width: "200px"}} src={logo} alt="логотип"/>

                <TextField
                    required
                    label="Имя"
                    type="text"
                    value={authData.name}
                    onChange={e => {
                        setError(false)
                        setAuthData({...authData, name: e.target.value});
                    }}
                    error={error}
                />

                <TextField
                    required
                    label="Пароль"
                    type="password"
                    value={authData.password}
                    onChange={e => {
                        setError(false)
                        setAuthData({...authData, password: e.target.value});
                    }}
                    error={error}
                />

                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%"}}>
                    <Button
                        type="submit"
                        variant="contained">Войти</Button>
                    <Button
                        onClick={clearInput}
                        variant="outlined">Очистить</Button>
                </Box>

            </Paper>
        </Container>
    );
}